/* Layouts */

.navbar {
  &.fixed-top {
    + .page-body-wrapper {
      padding-top: $navbar-height;
    }
  }
}

// Sidebar Mini
.sidebar-mini {
  @media (min-width: 992px) {
    .navbar {
      .navbar-brand-wrapper {
        width: $sidebar-width-mini;
      }
      .navbar-menu-wrapper {
        width: calc(100% - #{$sidebar-width-mini});
      }
    }
    .sidebar {
      width: $sidebar-width-mini;
      .nav {
        .nav-item {
          padding: 0;
          margin: 0;
          .nav-link {
            @include display-flex;
            @include align-items(center);
            @include justify-content(center);
            @include flex-direction(column-reverse);
            text-align: center;
            position: relative;
            border-bottom: none;
            .menu-title {
              display: block;
              margin: auto;
            }
            .menu-sub-title {
              margin: auto;
            }
            .badge {
              margin-left: 5px;
              display: none;
            }
            i {
              &.menu-icon {
                display: block;
                margin-right: auto;
                margin-left: auto;
              }
              &.menu-arrow {
                display: inline-block;
                margin-left: 5px;
                position: absolute;
                top: 50%;
                right: 10px;
                @include transform(translateY(-50%));
                &:before {
                  content: "\F140";
                }
              }
            }
            &[aria-expanded="true"] {
              .menu-arrow {
                &:before {
                  content: "\f143";
                }
              }
            }
          }
        }
      }
    }

    .main-panel {
      width: calc(100% - #{$sidebar-width-mini});
    }
    &:not(.sidebar-icon-only) {
      //Apply these styles only when sidebar-mini is not collapsed to icon-only mode
      .sidebar {
        .nav {
          &:not(.sub-menu) {
            > .nav-item {
              border-bottom: 1px solid rgba($sidebar-dark-menu-color, 0.2);
              .nav-link {
                height: auto;
                padding: $sidebar-mini-menu-padding;
                i {
                  &.menu-icon {
                    margin-bottom: 0.5rem;
                  }
                }
              }
            }
          }
          &.sub-menu {
            padding: 0;
            border-top: none;
            .nav-item {
              .nav-link {
                padding: 7px 0 7px 25px;
                margin-left: auto;
                margin-right: auto;
                display: block;
                text-align: left;
                width: 66%;
              }
            }
          }
        }
      }
    }
  }
}

// Sidebar Icon Only
.sidebar-icon-only {
  .sidebar {
    width: $sidebar-width-icon;
    .nav {
      overflow: visible;
      .nav-item {
        position: relative;
        padding: 0;
        .nav-link {
          display: block;
          text-align: center;
          .menu-title,
          .badge,
          .menu-sub-title {
            display: none;
          }
          .menu-title {
            @include border-radius(0 5px 5px 0px);
            @at-root #{selector-append(".rtl", &)} {
              @include border-radius(5px 0 0 5px);
            }
          }
          i {
            &.menu-icon {
              margin-right: 0;
              margin-left: 0;
            }
            &.menu-arrow {
              display: none;
            }
          }
          &[aria-expanded] {
            .menu-title {
              @include border-radius(0 5px 0 0px);
              @at-root #{selector-append(".rtl", &)} {
                @include border-radius(5px 0 0 0);
              }
            }
          }
        }
        &.nav-profile {
          display: none;
        }
        &.nav-category {
          display: none;
        }
        &.nav-doc {
          margin: 0;
          i {
            display: block;
          }
        }
        .collapse {
          display: none;
        }
        &.hover-open {
          .nav-link {
            .menu-title {
              @include display-flex;
              @include align-items(center);
              background: $icon-only-menu-bg-light;
              @at-root #{selector-append(".sidebar-dark", &)} {
                background: $sidebar-dark-menu-active-bg;
              }
              padding: 0.5rem 1.4rem;
              left: $sidebar-width-icon;
              position: absolute;
              text-align: left;
              top: 0;
              bottom: 0;
              width: $icon-only-collapse-width;
              z-index: 1;
              line-height: 1.8;
              @at-root #{selector-append(".rtl", &)} {
                left: auto;
                right: $sidebar-width-icon;
                text-align: left;
              }
              @at-root #{selector-append(".sidebar-dark", &)} {
                color: $white;
              }
              &:after {
                display: none;
              }
            }
          }
          .collapse,
          .collapsing {
            display: block;
            background: $icon-only-menu-bg-light;
            @include border-radius(0 0 5px 0);
            @at-root #{selector-append(".sidebar-dark", &)} {
              background: $sidebar-dark-bg;
            }
            position: absolute;
            left: $sidebar-width-icon;
            width: $icon-only-collapse-width;
            @at-root #{selector-append(".rtl", &)} {
              left: auto;
              right: $sidebar-width-icon;
              @include border-radius(0 0 0 5px);
            }
          }
        }
      }
      &.sub-menu {
        padding: $sidebar-icon-only-submenu-padding;
        .nav-item {
          .nav-link {
            text-align: left;
            padding-left: 20px;
          }
        }
        @at-root #{selector-append(".rtl", &)} {
          .nav-item {
            margin-right: auto;
            margin-left: 0;
            .nav-link {
              text-align: right;
              &:before {
                left: 0;
                right: unset;
              }
            }
          }
        }
      }
    }
    .sidebar-actions {
      display: none;
    }
  }
  //  @media (min-width: 992px) {
  //   .navbar {
  //     .navbar-brand-wrapper {
  //       width: $sidebar-width-icon;
  //       .brand-logo {
  //         display: none;
  //       }
  //       .brand-logo-mini {
  //         display: inline-block;
  //       }
  //     }
  //     .navbar-menu-wrapper {
  //       width: calc(100% - #{$sidebar-width-icon});
  //     }
  //   }
  //   .sidebar {
  //     width: $sidebar-width-icon;
  //     .nav {
  //       overflow: visible;
  //       .nav-item {
  //         position: relative;
  //         padding: 0;
  //         .nav-link {
  //           display: block;
  //           text-align: center;
  //           .menu-title,
  //           .badge,.menu-sub-title {
  //             display: none;
  //           }
  //           .menu-title {
  //             @include border-radius(0 5px 5px 0px);
  //             @at-root #{selector-append(".rtl", &)} {
  //               @include border-radius(5px 0 0 5px);
  //             }
  //           }
  //           i {
  //             &.menu-icon {
  //               margin-right: 0;
  //               margin-left: 0;
  //             }
  //             &.menu-arrow {
  //               display: none;
  //             }
  //           }
  //           &[aria-expanded] {
  //             .menu-title{
  //               @include border-radius(0 5px 0 0px);
  //               @at-root #{selector-append(".rtl", &)} {
  //                 @include border-radius(5px 0 0 0);
  //               }
  //             }
  //           }
  //         }
  //         &.nav-profile {
  //           display: none;
  //         }
  //         &.nav-category {
  //           display: none;
  //         }
  //         &.nav-doc {
  //           margin: 0;
  //           i {
  //             display: block;
  //           }
  //         }
  //         .collapse {
  //           display: none;
  //         }
  //         &.hover-open {
  //             .nav-link {
  //               .menu-title {
  //                 @include display-flex;
  //                 @include align-items(center);
  //                 background: $icon-only-menu-bg-light;
  //                 @at-root #{selector-append(".sidebar-dark", &)} {
  //                     background: $sidebar-dark-menu-active-bg;
  //                 }
  //                 padding: 0.5rem 1.4rem;
  //                 left: $sidebar-width-icon;
  //                 position: absolute;
  //                 text-align: left;
  //                 top: 0;
  //                 bottom: 0;
  //                 width: $icon-only-collapse-width;
  //                 z-index: 1;
  //                 line-height: 1.8;
  //                 @at-root #{selector-append(".rtl", &)} {
  //                   left: auto;
  //                   right: $sidebar-width-icon;
  //                   text-align: left;
  //                 }
  //                 @at-root #{selector-append(".sidebar-dark", &)} {
  //                   color: $white;
  //                 }
  //                 &:after {
  //                   display: none;
  //                 }
  //               }
  //             }
  //             .collapse,
  //             .collapsing {
  //               display: block;
  //               background: $icon-only-menu-bg-light;
  //               @include border-radius(0 0 5px 0);
  //               @at-root #{selector-append(".sidebar-dark", &)} {
  //                   background: $sidebar-dark-bg;
  //               }
  //               position: absolute;
  //               left: $sidebar-width-icon;
  //               width: $icon-only-collapse-width;
  //               @at-root #{selector-append(".rtl", &)} {
  //                   left: auto;
  //                   right:$sidebar-width-icon;
  //                   @include border-radius(0 0 0 5px);
  //               }
  //             }
  //           }
  //       }
  //       &.sub-menu {
  //         padding: $sidebar-icon-only-submenu-padding;
  //         .nav-item {
  //           .nav-link {
  //             text-align: left;
  //             padding-left: 20px;
  //           }
  //         }
  //         @at-root #{selector-append(".rtl", &)} {
  //           .nav-item {
  //             margin-right: auto;
  //             margin-left: 0;
  //             .nav-link {
  //               text-align: right;
  //               &:before{
  //                 left: 0;
  //                 right: unset;
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //     .sidebar-actions {
  //       display: none;
  //     }
  //   }

  //   .main-panel {
  //     width: calc(100% - #{$sidebar-width-icon});
  //   }
  // }
}

// Hidden Sidebar
.sidebar-hidden {
  @media (min-width: 992px) {
    .sidebar {
      // transition: width $action-transition-duration $action-transition-timing-function;
      // -webkit-transition: width $action-transition-duration $action-transition-timing-function;
      // -moz-transition: width $action-transition-duration $action-transition-timing-function;
      // -ms-transition: width $action-transition-duration $action-transition-timing-function;
      // width: 0;
    }

    .main-panel {
      width: 100%;
    }
  }
}

// Absolute sidebar with overlay to content
.sidebar-absolute {
  @media (min-width: 992px) {
    .page-body-wrapper {
      position: relative;

      .sidebar {
        transition: none;
      }
    }

    &:not(.sidebar-hidden) {
      .sidebar {
        position: absolute;
        height: 100%;
        -webkit-box-shadow: 0 0 3px 1px #a7a3a3;
        /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
        -moz-box-shadow: 0 0 3px 1px #a7a3a3;
        /* Firefox 3.5 - 3.6 */
        box-shadow: 0 0 3px 1px #a7a3a3;
        /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
      }
    }

    .main-panel {
      width: 100%;
      transition: none;
    }
  }
}

//Fixed sidebar
.sidebar-fixed {
  @media (min-width: 992px) {
    .sidebar {
      position: fixed;
      max-height: auto;

      .nav {
        max-height: calc(100vh - #{$navbar-height});
        overflow: auto;
        position: relative;

        &.sub-menu {
          max-height: none;
        }
      }
    }

    .main-panel {
      margin-left: $sidebar-width-lg;
    }

    &.sidebar-icon-only {
      .main-panel {
        margin-left: $sidebar-width-icon;
      }
    }
  }
}

//Boxed layout
.boxed-layout {
  @media (min-width: 992px) {
    .container-scroller {
      background: #e1d7ea;
      padding: 0 calc((100% - #{$boxed-container-width}) / 2);
    }
    .navbar {
      &.fixed-top {
        margin: auto;
        max-width: $boxed-container-width;
      }
    }
  }
}

//RTL layout
.rtl {
  direction: rtl;
  text-align: right;

  .sidebar {
    .nav {
      padding-right: 0;
    }
  }

  .product-chart-wrapper,
  .settings-panel .tab-content .tab-pane .scroll-wrapper,
  .sidebar-fixed .nav,
  .table-responsive,
  ul.chats {
    &::-webkit-scrollbar {
      width: 0.5em;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      outline: 1px solid slategrey;
    }
  }
}

.content-page {
  margin-left: 320px;
  overflow: hidden;
  min-height: 100vh;
  padding: 0px 20px;
  background-color: #eeeeee;
}

.sidebar-icon-only {
  .content-page {
    margin-left: 70px;
    background-color: #eeeeee;
  }
}
input[type=number].input-hide-spinner { -moz-appearance: textfield; }
input[type=number].input-hide-spinner::-webkit-inner-spin-button,
input[type=number].input-hide-spinner::-webkit-outer-spin-button {
-webkit-appearance: none;
margin: 0;
}
// react-datepicker css Start
.react-datepicker
  .react-datepicker__month-container
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day.react-datepicker__day--selected::before,
.react-datepicker
  .react-datepicker__month-container
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day.react-datepicker__day--today::before {
  width: 26px;
  height: 26px;
}
.react-datepicker
  .react-datepicker__month-container
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day {
  padding: 0px;
}

// react-datepicker css End
